import React from "react";
import Mobile1 from "../../Assets/mobile1.png";
import Mobile2 from "../../Assets/mobile2.png";
import Star1 from "../../Assets/star1.svg";
import Star2 from "../../Assets/star2.svg";
const HeroSlider = () => {
  return (
    <div>
      <div>
        <img src={Star1} alt="img" className="star-1" />
        <img src={Mobile1} alt="img" className="st-hero-slider1" />
        <img src={Star2} alt="img" className="star-2" />
        <img src={Mobile2} alt="img" className="st-hero-mobile2" />
      </div>
    </div>
  );
};

export default HeroSlider;
