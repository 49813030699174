import React from "react";
import Hero from "../Components/Hero/Hero";
import Features from "../Components/Features/Features";
import Faqs from "../Components/Faqs/Faqs";
import Testimonial from "../Components/Testimonials/Testimonial";
import Gallery from "../Components/Gallery/Gallery";
import ContactUs from "../Components/ContactUs/ContactUs";

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
      <Gallery />
      <Testimonial />
      <Faqs />
      <ContactUs />
      <hr />
    </>
  );
};

export default Home;
