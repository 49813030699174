import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Fade,
  Typography,
} from "@mui/material";
import DownIcon from "../../Assets/DownIcon.svg";
const Faqs = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <section id="faqs" className="st-shape-wrap st-gray-bg sections">
      <SectionHeading
        id="faqs"
        title="Frequently Asked Questions"
        subTitle="Have questions? We have answers! Explore our frequently asked questions to find quick <br />solutions to common inquiries."
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ">
            <div className="accordian-res">
              <Divider style={{ marginTop: "30px" }} />
              <Accordion
                expanded={expanded}
                onChange={handleExpansion}
                slots={{ transition: Fade }}
                slotProps={{ transition: { timeout: 400 } }}
                sx={{
                  padding: "0px",
                  background: "none",
                  boxShadow: "none",
                  "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
                  "& .MuiAccordionDetails-root": {
                    display: expanded ? "block" : "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first2" />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    What is PalFlock?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    PalFlock is a social networking app that helps users find
                    like-minded individuals nearby or online to engage in
                    various activities together.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first2" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    How does PalFlock work?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Users can create posts, known as "Intents," specifying an
                    activity they want to do and the location. Other users
                    interested in joining can swipe right to express interest
                    and wait for the initiator of the intent to initiate the
                    chat.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first3" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    What kind of activities can I find on PalFlock?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can find a wide range of activities on PalFlock for both
                    real-life and online interactions, including but not limited
                    to movies, sports, dining out, hiking, gaming, and more.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first2" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    Is PalFlock free to use?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, PalFlock is free to download and use and has no added
                    costs for premium features.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first2" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    How do I download PalFlock?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can download PalFlock from the App Store (for iOS
                    devices) or Google Play Store (for Android devices).
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first6" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    Do I need to create an account to use PalFlock?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, you need to create an account to be able to use
                    PalFlock. You can sign up using your email address, phone
                    number, or social media accounts.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<img src={DownIcon} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    How can I report inappropriate behavior on PalFlock?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    If you encounter any inappropriate behavior or content on
                    PalFlock, please report it immediately using the in-app
                    reporting feature. Our team will investigate the issue and
                    take appropriate action.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first8" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    Can I use PalFlock to organize group activities?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, you can create Intents for group activities and specify
                    the group size. Other users can express interest, and you
                    can chat with them to coordinate the details.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first9" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    Is my personal information secure on PalFlock?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We take the security and privacy of our users seriously.
                    PalFlock employs industry-standard security measures to
                    protect your personal information. Please refer to our
                    Privacy Policy for more details.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
              <Accordion
                sx={{ background: "none", border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img src={DownIcon} className="expand-icon first10" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    How can I contact PalFlock for support or inquiries?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can contact PalFlock's customer support team by emailing
                    support@palflock.com or using the in-app support feature. We
                    are here to assist you with any questions or concerns you
                    may have.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Divider style={{ marginBottom: "20px" }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Faqs;
