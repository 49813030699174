import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Gallery1 from "../../Assets/Gallery1.png";
import Gallery2 from "../../Assets/Gallery2.png";
import Gallery3 from "../../Assets/Gallery3.png";
const Gallery = () => {
  const breakPointColumns = {
    default: 3,
    1199: 2,
    767: 1,
  };

  return (
    <section className="sections" id="gallery" style={{ background: "#F8F8F8" }}>
      <div className="container">
        <SectionHeading
          title="Gallery"
          subTitle=" Stunning images and snapshots from events to behind-the-scenes glimpses, our <br />gallery is a window into the unique stories that define us."
        />
      </div>
      <div className="container">
        <div className="st-portfolio-wrapper">
          <div
            className="st-isotop col-12 d-flex st-style1 st-has-gutter st-lightgallery my-masonry-grid"
            breakpointCols={breakPointColumns}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 galleries">
                  <div className="st-project-img">
                    <img
                      className="st-hover-hidden galler1"
                      src={Gallery1}
                      alt="image"
                    />
                  </div>
                </div>
                <div className="col-lg-6 galleries">
                  <div className="row">
                    <div className="col-lg-12 gallery2-3-col">
                      <div className="st-project-img">
                        <img
                          className="st-hover-hidden gallery2"
                          src={Gallery2}
                          alt="image"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 gallery2-3-col">
                      <div className="st-project-img">
                        <img
                          className="st-hover-hidden gallery3"
                          src={Gallery3}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
