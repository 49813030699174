import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Feature1 from "../../Assets/Feature1.png";
import Feature2 from "../../Assets/Feature2.png";
const Features = () => {
  return (
    <section className="sections" id="features">
      <SectionHeading
        title="Features"
        subTitle="Uncover a seamless experience with our platform's 
        intuitive design. <br />Explore the difference first-hand."
      />
      <div className="container">
        <div className="st-tabs st-fade-tabs st-style1">
          <div className="st-imagebox st-style2">
            <div className="row">
              <div className="col-lg-6">
                <div className="st-imagebox-img">
                  <img src={Feature1} alt="feature-image" />
                </div>

                <div className="st-height-b0 st-height-lg-b30" />
              </div>
              <div className="col-lg-6">
                <div className="st-vertical-middle">
                  <div className="st-vertical-middle-in">
                    <div className="st-imagebox-info">
                      <h2 className="st-imagebox-title">
                        Create and join local <br />
                        adventures
                      </h2>
                      <div className="st-imagebox-text">
                        Create and join events for online or offline encounters.
                        Whether it is a casual get-together or an exciting
                        outing, dive into a world of shared experiences,
                        laughter and memories.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="st-imagebox st-style2">
            <div className="row">
              <div className="col-lg-6">
                <div className="st-vertical-middle">
                  <div className="st-vertical-middle-in">
                    <div className="st-imagebox-info">
                      <h2 className="st-imagebox-title">
                        Connect and chat with <br />
                        Pals
                      </h2>
                      <div className="st-imagebox-text">
                        Connect effortlessly, share unforgettable moments, and
                        enjoy lively conversations with future friends on
                        PalFlock. Our platform simplifies socializing and brings
                        people closers for a fun and easy chat experience.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="st-imagebox-img">
                  <img src={Feature2} alt="feature-image" />
                  {/* <img
                    src={Feature2}
                    alt="feature-image"
                    // srcSet={`${Feature2} 4x`}
                    // sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 25vw"
                    style={{
                      // transform: "translateZ(0)",
                      // objectFit: "cover",
                      // height: "100%",
                      // width: "100%",
                      imageRendering: "pixelated",
                      // filter: "blur(0.5px)",
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
