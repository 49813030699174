import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import TestimonialImg from "../../Assets/Testimonial.png";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

const Testimonial = () => {
  return (
    <section className="sections testimonial-sec" id="testimonial">
      <SectionHeading
        title="Testimonials"
        subTitle="Read our users' testimonials that highlight the joy, connection, and 
        seamless communication <br />they've found on the platform."
      />
      <div className="swiper-part mt-4 mt-lg-5">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide>
            <img src={TestimonialImg} className="image-slider-res" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TestimonialImg} className="image-slider-res" />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonial;
